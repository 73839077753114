<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/exam-result' }">
        <app-i18n code="entities.examResult.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.examResult.analyze.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="analyze-area" v-if="!loading">
      <div class="analyze-sidebar">
        <div class="side-title">
          <h2>{{ examName }}</h2>
        </div>
        <ul class="analyze-list">
          <li
                  :key="index"
                  v-for="(item, index) in rows"
                  v-bind:class="{ active: index === selectedIndex }"
                  @click="switchQuestion(index)"
          >
            <span class="i-number">{{ item.questionNumber }}</span>
            <span class="i-type">{{ getTypeLabel(item.question.questionType.questionType) }}</span>
            <!--          <span class="i-section">{{getSectionLabel(item.question.questionType.section)}}</span>-->
          </li>
        </ul>
        <div class="analyze-nav">
          <el-button
                  @click="switchQuestion(selectedIndex - 1)"
                  :disabled="selectedIndex === 0"
          ><i
                  class="text-contrast raw-icon el-icon-fa-chevron-left"
          ></i>
            <app-i18n code="entities.examResult.analyze.prev"></app-i18n></el-button
          >
          <span v-if="rows">{{ selectedIndex + 1 }} / {{ rows.length }}</span>
          <el-button v-if="rows"
                     @click="switchQuestion(selectedIndex + 1)"
                     :disabled="selectedIndex >= rows.length - 1"
          ><app-i18n code="entities.examResult.analyze.next"></app-i18n>
            <i
                    class="text-contrast raw-icon el-icon-fa-chevron-right"
            ></i
            ></el-button>
        </div>
      </div>
      <div class="analyze-preview">
        <div
                class="preview-area analyze-true"
                v-if="!loading"
        >
          <el-form
                  :label-position="labelPosition"
                  :label-width="labelWidthForm"
                  @submit.prevent.native
                  class="form"
          >
            <div class="m-preview" v-if="current">
              <div class="pre-head">
                <h1>{{getCurrentQuestionTitle()}}</h1>
                <span v-if="rows" class="p-pager">
                {{ selectedIndex + 1 }} /
                {{ rows.length }}</span
                >
              </div>
              <div class="pre-content">
                <component
                        :record="current.question"
                        :isAnalyze="true"
                        :description="toDescription()"
                        :key="selectedIndex"
                        :data="current"
                        v-bind:is="getCurrentTypeName()"
                />
              </div>
              <div class="pre-footer"></div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { QuestionModel } from '@/modules/question/question-model';
import readAloud from '@/modules/question/components/preview/readAloud';
import repeatSentence from '@/modules/question/components/preview/repeatSentence';
import describeImage from '@/modules/question/components/preview/describeImage';
import retellLecture from '@/modules/question/components/preview/retellLecture';
import answerShortQuestion from '@/modules/question/components/preview/answerShortQuestion';
import summarizeWrittenText from '@/modules/question/components/preview/summarizeWrittenText';
import writeEssay from '@/modules/question/components/preview/writeEssay';
import singleOption from '@/modules/question/components/preview/singleOption.vue';
import multipleOption from '@/modules/question/components/preview/multipleOption.vue';
import reorderParagraph from '@/modules/question/components/preview/reorderParagraph.vue';
import fillInTheBlanks from '@/modules/question/components/preview/fillInTheBlanks.vue';
import selectedFillInTheBlanks from '@/modules/question/components/preview/selectedFillInTheBlanks.vue';
import highlightCorrectSummary from '@/modules/question/components/preview/highlightCorrectSummary.vue';
import summarizeSpokenText from '@/modules/question/components/preview/summarizeSpokenText.vue';
import multipleAnswer from '@/modules/question/components/preview/multipleAnswer.vue';
import writeInTheBlanks from '@/modules/question/components/preview/writeInTheBlanks.vue';
import singleAnswer from '@/modules/question/components/preview/singleAnswer.vue';
import selectMissingWord from '@/modules/question/components/preview/selectMissingWord.vue';
import highlightIncorrectWords from '@/modules/question/components/preview/highlightIncorrectWords';
import writeFromTheDictation from '@/modules/question/components/preview/writeFromTheDictation.vue';
import UtilsModules from '@/shared/utils-for-modules';

export default {
  name: 'app-exam-result-analyze-page',
  props: ['examId','studentId'],
  components: {
    readAloud,
    repeatSentence,
    describeImage,
    retellLecture,
    answerShortQuestion,
    summarizeWrittenText,
    writeEssay,
    singleOption,
    multipleOption,
    reorderParagraph,
    fillInTheBlanks,
    selectedFillInTheBlanks,
    highlightCorrectSummary,
    summarizeSpokenText,
    multipleAnswer,
    writeInTheBlanks,
    singleAnswer,
    selectMissingWord,
    highlightIncorrectWords,
    writeFromTheDictation,
    // multipleAnswer,
    // writeInTheBlanks,
    // singleAnswer,
  },
  data() {
    return {
      selectedIndex: 0,
      current: null,
    };
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      hasAdminRole: 'auth/hasAdminRole',
      rows: 'examOutput/analyze/rows',
      loading: 'examOutput/analyze/loading',
      examName: 'examOutput/analyze/examName',
    }),
    typeDescription() {
      return 'typeDescription';
    },
    type() {
      return 'type';
    },
    typeLabel() {
      return 'typeLabel';
    },
  },
  async created() {
    await this.doFind({examId: this.examId, studentId: this.studentId});
  },
  methods: {
    ...mapActions({
      doFind: 'examOutput/analyze/doFind',
    }),
    toTypeLabel() {
      return 'deneme';
    },

    switchQuestion(index) {
      this.selectedIndex = index;
      this.current = this.rows[index];
    },
    toDescription() {
      return UtilsModules.questionDescription(this.current.question,this.getCurrentTypeName());
    },
    getCurrentTypeName() {
      return UtilsModules.convertQTypeForExam(this.current.question.questionType.questionType);
    },
    getCurrentQuestionTitle(){
      return UtilsModules.questionTitle(this.examName,
              this.current.question.questionType.section,
              this.getCurrentTypeName());
    },
    getSectionLabel(section){
      return UtilsModules.sectionLabel(section);
    },
    getTypeLabel(questionType) {
      const qTypeName = UtilsModules.convertQTypeForExam(questionType);
      return  UtilsModules.questionTypeLabel(qTypeName);
    },
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    }
  },
  watch: {
    '$store.state.examOutput.analyze.rows'() {
      if (this.rows && this.rows.length > 0) {
        this.current = this.rows[0];
      }
    },
  },
};
</script>

<style>
.analyze-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1.5rem;
}



.analyze-preview {
  flex: 4;
}
.preview-area {
  background-color: white;
  padding: 1rem;
  border: 1px solid #efefef;
  position: relative;
}

.preview-area > h1 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
}
/*.preview-area.analyze-false {*/
/*  !*width: 50%;*!*/
/*  margin: auto;*/
/*  width: 70%;*/
/*  margin-top: 2rem;*/
/*}*/
/*.preview-area.analyze-false > h1 {*/
/*  text-align: left;*/
/*}*/

.analyze-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 15px;
}

.analyze-nav > button {
  background-color: #a51e36;
  color: white;
  display: flex;
  border: none;
}

.analyze-nav > span {
  color: #a51e36;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.subcontent-options > ul > li.response {
  color: #a51e36;
}

.subcontent-options > ul > li.response.active {
  border-color: green;
}

.subcontent-options > ul > li {
  position: relative;
}

/*.subcontent-options > ul > li.response.active:after {*/
/*  content: '\2713';*/
/*  color: green;*/
/*  position: absolute;*/
/*  right: 15px;*/
/*  display: none;*/
/*}*/

.subcontent-options > ul > li {
  position: relative;
}

.subcontent-options > ul > li.response:before {
  content: '\2716';
  left: -20px;
  right: unset;
  position: absolute;
}

.subcontent-options > ul > li.response.active:before {
  content: '\2713';
  color: green;
  left: -20px;
  font-weight: bold;
  position: absolute;
}

.subcontent-options > ul > li.active {
  color: green;
}

.subcontent-options > ul > li {
  padding: 8px;
  margin-bottom: 12px;
  font-size: 15px;
  border: 1px solid #efefef;
}

.subcontent-drag-options li {
  background-color: white;
}

.tool-src {
  display: flex;
  justify-content: center;
}

.subcontent.highlight-options {
  width: 50%;
  text-align: left;
  padding-top: 15px;
}

.subcontent.highlight-options > div {
  margin-bottom: 15px;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #efefef;
  position: relative;
}

.subcontent.highlight-options > div.active.response:before {
  content: '\2713';
  left: -1rem;
  font-weight: bold;
  position: absolute;
  top: 36%;
}
.subcontent.highlight-options > div.response:before {
  content: '\2716';
  left: -1rem;
  font-weight: bold;
  position: absolute;
  top: 36%;
}

.subcontent.highlight-options > div.response {
  border-color: #a51e36;
  color: #a51e36;
}


.clear-ul {
  padding-left: 0;
}

.lined-options p {
  line-height: 2;
  text-align: left;
  text-indent: 15px;
}

span.lined {
  background-color: limegreen;
  color: white;
  padding: 4px;
}
span.lined.liHighlightCorrect{
  background-color: green;
}
span.lined.liHighlightCorrect:before{
  content: '\2713';
  margin-right: 0.2rem;
  font-weight: bold;
}
span.lined.liWrong {
  background-color: #a51e36;
}

span.lined.wrongBG {
  background-color: #a51e36;
}

span.lined.liHighlightWrong:before{
  content: '\2716';
  margin-right: 0.2rem;
  font-weight: bold;
}



span.lined.liCorrect {
  background-color: green;
}

select.select-options {
  background-color: white;
  color: #a51e36;
  padding: 4px 12px;
  border: 1px solid #a51e36;
  border-radius: 4px;
}

.lh-2 {
  line-height: 2;
}

span.correct-fill {
  padding: 0.25rem;
  background-color: green;
  color: white;
  border-radius: 0.25rem;
}

.single-preview .subtitle {
  position: absolute;
  right: 30px;
  top: 5px;
}
.subtitle > h4 {
  color: #a61f36;
}
.section-content > p {
  font-size: 14px;
  line-height: 1.4;
}

.lh2 {
  line-height: 2;
}

.section-title {
  font-weight: bold;
}

.w-50 {
  width: 50% !important;
}

.section-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #efefef;
  padding-top: 10px;
}

.section-footer > div {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 1px;
}

.text-center {
  text-align: center;
}

.all-options {
  display: flex;
  justify-content: center;
}

.all-options > div {
  padding: 8px;
}

.all-options span {
  display: block;
  text-align: center;
  background-color: #fbfbfb;
  padding: 5px 8px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  border: 1px solid #efefef;
  border-radius: 8px;
  color: green;
}

.section-footer > div > span:first-child {
  font-weight: 500;
  padding-right: 5px;
}

.preview-area {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  border: none;
  padding: 1.5rem;
}

.pre-head > h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.text-left {
  text-align: left !important;
}

.single-preview h3 {
  font-size: 15px;
  text-align: center;
}

.subcontent-options > ul {
  list-style-type: none;
}



.pre-head {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: linear-gradient(60deg, #da3553, #8f1a2f);
  color: white;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
}

.subhead {
  text-align: center;
  width: 76%;
  margin: auto;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.pre-tool {
  width: 400px;
  margin: auto;
  margin-top: 3rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tool-top {
  padding: 0.75rem;
  text-align: center;
  width: 300px;
  background: linear-gradient(60deg, #66bb6a, #388e3c);
  color: white;
  margin: auto;
  margin-top: -2rem;
  border-radius: 0.75rem;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}

.tool-value {
  height: 3rem;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.tool-top > span {
  padding-left: 1rem;
}

.subcontent {
  padding: 0.5rem 1.2rem;
  text-align: center;
  background-color: #f4f4f461;
  border: 1px solid #efefef;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.single-preview.preview-repeat-sentence .subcontent {
  width: 400px;
  padding-top: 0.75rem;
}

.image-preview {
  width: 50%;
  margin: auto;
  margin-top: 3rem;
}

.subcontent.highlight-options > div.active {
  border-color: green;
  color: white;
  background-color: green;
}

.analyze-sidebar {
  flex: 1;
  background-color: white;
  padding: 8px;
  margin-left: 1rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

ul.analyze-list {
  list-style-type: none;
  margin-bottom: 0;
  max-height: 75vh;
  padding-right: 8px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-left: 24px;
}

ul.analyze-list:hover {
  overflow-y: scroll;
}

.side-title > h2 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
  text-align: center;
  color: #a51e36;
}

ul.analyze-list > li {
  position: relative;
  margin-bottom: 12px;
  padding: 4px;
  font-size: 14px;
  color: #3a3d3c;
  background-color: #f0f2f5;
  margin-top: 6px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  transition: all 0.25s ease 0.25s;
}

ul.analyze-list > li > span.i-number {
  position: absolute;
  left: -24px;
  height: 100%;
  top: 0;
  color: #a51e36;
  display: flex;
  width: 20px;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

span.i-section {
  display: none;
}

span.i-type {
  font-weight: 600;
  font-size: 13px;
}

ul.analyze-list > li:hover {
  color: #a51e36;
}
ul.analyze-list > li.active {
  color: #a51e36;
}

.audio-box.resp-box {
  width: 50%;
  margin: auto;
  padding: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #fbfbfb;
}

.resp-box > h3 {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 600;
  color: #a51e36;
}

.audio-resp > audio {
  margin: auto;
  display: block;
}

.analyze-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 15px;
}

.analyze-nav>button {
  background-color: #a51e36;
  color: white;
  display: flex;
  border: none;
}

.analyze-nav>span {
  color: #a51e36;
  font-weight: bold;
}

.analyze-preview {
  max-height: 90vh;
  /*margin-bottom: 20px;*/
  overflow-y: scroll;
}

.preview-area.analyze-true {
  width: 85%;
  margin: 0 auto 1rem auto;
}

</style>
